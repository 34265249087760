import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { EventMessage } from '../messages/event.message';
import { VerifyCode } from '../model/VerifyCode';
import { ResendCode } from '../model/ResendCode';

@Injectable({
  providedIn: 'root'
})
export class GatewayService {

    private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json'});

    constructor(
        private http: HttpClient,
        private eventMessage : EventMessage,
    ) { }





   verify(code:VerifyCode): Observable<any> {
       return this.http.post<any[]>(`${environment.URL_API}/verify`, code).pipe(
            catchError(e => {

                this.eventMessage.error(e.error.error);
                return throwError(e);
              })
        )
    }

    resend(code:ResendCode): Observable<any> {
        return this.http.post<any[]>(`${environment.URL_API}/resend`, code).pipe(
             catchError(e => {
 
                 this.eventMessage.error(e.error.error);
                 return throwError(e);
               })
         )
     }

     status(processId:string): Observable<any> {
        console.log(`${environment.URL_API}/process/`+processId);
        
        return this.http.get<any[]>(`${environment.URL_API}/process/`+processId).pipe(
             catchError(e => {
 
                 this.eventMessage.error(e.error.error);
                 return throwError(e);
               })
         )
     }
}

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventMessage } from '../core/messages/event.message';
import { ResendCode } from '../core/model/ResendCode';
import { VerifyCode } from '../core/model/VerifyCode';
import { GatewayService } from '../core/services/gateway.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public article: any;
  public published_at: any;
  @ViewChild('container') d1: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private eventMessage: EventMessage,
    private readonly gatewayService: GatewayService) { }

    public isFinished : boolean = false;
    public returnUrl : string = '';

  private _data = {
    lblTitle: 'We have sent a verification code to your email',
    lblResend: 'Didn\'t receive code?',
    btnResend: 'Request again',
    btnVerify: 'Verify',
    lnkReturn: 'Return',
    successMessage : 'Your process was succesful!'

  };

  @Input()
  public set data(data: any) {
    this._data = data;
  }

  public get data() {
    return this._data;
  }




  otp: string = '';
  otpDigits: string[] = ['', '', '', '', '', ''];
  @ViewChild('digit1') digit1!: ElementRef;
  @ViewChild('digit2') digit2!: ElementRef;
  @ViewChild('digit3') digit3!: ElementRef;
  @ViewChild('digit4') digit4!: ElementRef;
  @ViewChild('digit5') digit5!: ElementRef;
  @ViewChild('digit6') digit6!: ElementRef;




  get isInputComplete(): boolean {
    return this.otpDigits.every(digit => digit !== '');
  }

  onInput(event: any, index: number) {
    const value = event.target.value;
    if (value.length === 1) {
      this.otpDigits[index - 1] = value;
      this.setFocus(index);
    } else if (value.length === 0) {
      this.otpDigits[index - 1] = '';
      this.setFocus(index - 1);
    }
  }

  setFocus(index: number) {
    switch (index) {
      case 1:
        this.digit2.nativeElement.focus();
        break;
      case 2:
        this.digit3.nativeElement.focus();
        break;
      case 3:
        this.digit4.nativeElement.focus();
        break;
      case 4:
        this.digit5.nativeElement.focus();
        break;
      case 5:
        this.digit6.nativeElement.focus();
        break;
      case 6:
        this.digit6.nativeElement.blur();
        break;
      default:
        this.digit1.nativeElement.focus();
    }
  }




  verifyOTP() {
    const otp = this.otpDigits.join('');
    const processId = this.route.snapshot.params['processId'];

    const codeInstance: VerifyCode = {
      processId: processId,
      code: otp,
    };


    this.gatewayService.verify(codeInstance).subscribe(result => {
  
      if(result.status == 'CLOSED'){
        this.isFinished = true;
        this.returnUrl = result.returnUrl;
      }else{
        this.eventMessage.info(result);
      }
    });


  }

  resendOTP() {
    const otp = this.otpDigits.join('');
    const processId = this.route.snapshot.params['processId'];

    const codeInstance: ResendCode = {
      processId: processId,
    };

    console.log(processId);

    this.gatewayService.resend(codeInstance).subscribe(result => {
      console.log(result);
      this.eventMessage.info(result);
    });

  }

  ngAfterViewInit() {


  }


  ngOnInit(): void {

    const processId = this.route.snapshot.params['processId'];

    this.gatewayService.status(processId).subscribe(result => {
      this._data.lblTitle += ' '+ result.sent;
      
      if(result.status == 'CLOSED'){
        this.isFinished = true;
        this.returnUrl = result.returnUrl;
        //this.eventMessage.info("Your process was succesful!");
      }
    });

  }

}


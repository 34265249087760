import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EventMessage } from 'src/app/core/messages/event.message';
import { GatewayService } from 'src/app/core/services/gateway.service';

@Component({
  selector: 'app-code',
  templateUrl: './code.component.html',
  styleUrls: ['./code.component.css']
 })
export class CodeComponent implements OnInit {
  public result: any; 

  public formCode: FormGroup  = this.fb.group({
      name: ['name', Validators.required],
    });

  constructor(
    private readonly fb: FormBuilder,         
    private readonly router: Router,
	private eventMessage : EventMessage,
    private readonly gatewayService: GatewayService
  ) { }

  ngOnInit() {

  }




		serviceTestCodeotp(){

			   
			      
			}
	}
